import {
  createCollectionPoint,
  deleteCollectionPoint,
  getCollectionPoints,
} from "../../../../../features/collectionPointSlice/collectionPointSlice";
import { useDispatch, useSelector } from "react-redux";

import Content from "./partials/Content";
import Modal from "../../../../../components/atoms/Modal";
import React from "react";
import Swal from "sweetalert2";
import { formSchema } from "./schema/formSchema";
import { selectModal } from "../../../../../features/modalSlice";
import { selectUser } from "../../../../../features/userSlice";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";

const MassCreateModal = ({
  isOpen,
  toggleModal,
  type,
  onRefreshData,
  estate,
  afdeling,
  block,
  params,
}) => {
  const UPDATE_TYPE = type.toLowerCase().includes("ubah");
  const dispatch = useDispatch();
  const alert = useAlert();
  const modalName = "CollectionPoint";
  const modal = useSelector(selectModal);
  const user = useSelector(selectUser);
  const [searchParams] = useSearchParams();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    clearErrors,
    watch,
  } = useForm({ resolver: yupResolver(formSchema) });

  const estateId = searchParams.get("estateId");
  const estateName = searchParams.get("estateName");
  const afdelingId = searchParams.get("afdelingId");
  const afdelingName = searchParams.get("afdelingName");
  const blockId = searchParams.get("blockId");
  const blockName = searchParams.get("blockName");
  const endTph = watch("endTph");
  const startTph = watch("startTph");

  const addMissingNumbers = async (start, end, numbers) => {
    console.log("start", start);
    console.log("end", end);
    console.log("numbers", numbers);

    const existingNumbers = new Set(numbers.map((n) => parseInt(n.name)));
    const newNumbers = Array.from(
      { length: end - start + 1 },
      (_, i) => start + i
    );
    const missingNumbers = newNumbers.filter(
      (num) => !existingNumbers.has(num)
    );

    const newNumberObjects = missingNumbers.map(async (num) => {
      const data = await handleCreateCollectionPoint(num);
      return data;
    });

    const updatedNumbers = await Promise.all(newNumberObjects);

    const removedNumbers = removeExtraNumbers(numbers, end);

    removedNumbers?.map((item) => handleDeleteCollectionPoint(item?.id));

    numbers.push(...updatedNumbers);
    numbers.sort((a, b) => parseInt(a.name) - parseInt(b.name));
  };

  const removeExtraNumbers = (numbers, count) => {
    if (numbers.length + 1 > count) {
      const removedNumbers = numbers.filter(
        (num) => parseInt(num.name) > count
      );

      return removedNumbers;
    }
  };

  const handleCreateCollectionPoint = async (name) => {
    let requestData = {
      name,
      afdelingId,
      afdelingName,
      estateId,
      estateName,
      blockId,
      blockName,
      userId: user.id,
      userName: user.username,
      companyId: user.companyId,
    };

    try {
      const response = await dispatch(
        createCollectionPoint(requestData)
      ).unwrap();
      const success = response.success;
      const message = response.message;
      if (!success) {
        alert.error(message);
      }
    } catch (error) {
      alert.error(error?.data);
    }
  };

  const handleDeleteCollectionPoint = async (id) => {
    try {
      const response = await dispatch(deleteCollectionPoint({ id })).unwrap();
      const success = response.success;
      const message = response.message;
      if (!success) {
        alert.error(message);
      }
      return response?.record;
    } catch (error) {
      alert.error(error?.data);
    }
  };

  const resetCollectionPointHanlder = async (records) => {
    const response = await dispatch(
      getCollectionPoints({
        ...params,
        pageSize: 999,
      })
    ).unwrap();

    response?.records?.map((item) => {
      handleDeleteCollectionPoint(item?.id);
    });

    toast.success("Berhasil mereset TPH");
  };

  const handleGetCollectionPoint = async () => {
    try {
      const response = await dispatch(
        getCollectionPoints({
          ...params,
          pageSize: 999,
        })
      ).unwrap();
      const success = response.success;
      const message = response.message;
      if (!success) {
        return alert.error(message);
      }
      if (parseInt(endTph) < response?.records?.length) {
        const result = await Swal.fire({
          title: "Apakah anda yakin akan mengubah jumlah TPH?",
          text: `Jumlah TPH yang anda masukkan ${parseInt(
            endTph
          )} dibandingkan dengan jumlah sekarang ${response?.records?.length}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#dc2626",
          confirmButtonText: "Ya, hapus!",
          cancelButtonText: "Tidak",
          reverseButtons: true,
          heightAuto: false,
        });
        if (result.isConfirmed) {
          addMissingNumbers(
            parseInt(startTph),
            parseInt(endTph),
            response?.records
          );
          setTimeout(() => {
            onRefreshData();
            toggleModal();
          }, 800);
        }
      } else {
        addMissingNumbers(
          parseInt(startTph),
          parseInt(endTph),
          response?.records
        );
        setTimeout(() => {
          onRefreshData();
          toggleModal();
        }, 800);
      }
    } catch (error) {
      return alert.error(error?.data);
    }
  };

  const onSubmit = () => {
    handleGetCollectionPoint();
  };

  const handleDefaultValue = () => {
    let state = modal[modalName]?.params?.item;
    clearErrors();
    reset();
    if (UPDATE_TYPE) {
      const currentData = state;
      reset(currentData);
    } else {
      const nullValues = Object.keys(state || {}).reduce((accumulator, key) => {
        return { ...accumulator, [key]: null };
      }, {});
      reset(nullValues);
    }
  };

  React.useEffect(() => handleDefaultValue(), [type, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggleModal}
      title={`${type} ${estate.name} / ${afdeling.name} / ${block.name}`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          <Content register={register} errors={errors} />
          {/*<p onClick={() => resetCollectionPointHanlder()}>Reset TPH</p>*/}
        </Modal.Content>
        <Modal.Footer
          toggleModal={toggleModal}
          type={`${type} ${endTph >= 1 ? `${parseInt(endTph || 0)} TPH` : ""}`}
        />
      </form>
    </Modal>
  );
};

export default MassCreateModal;
