import InputHook from "../../../../../../../components/atoms/InputHook";
import React from "react";

const Content = ({ register, errors }) => {
	return (
		<div className="grid grid-cols-1 gap-13">
			<InputHook
				type="number"
				register={register}
				label="Nama/Kode TPH"
				name="name"
				placeholder="Masukkan nama/kode tph"
				errors={errors?.name}
				inputClassName="input-large-border"
			/>
		</div>
	);
};

export default Content;
